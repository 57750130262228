<template>
    <div class="jiaoban">
        <!-- 交班退出 -->
        <Shift :showShift="showShift" v-if="showShift" @hidn="goYingye"></Shift>
    </div>
</template>
<script>
import Shift from "../../views/yingye/modules/shift/shift.vue"
import { mapState , mapActions } from "vuex";
export default {
    data(){
        return{
            showShift:true
        }
    },
    components: { Shift },
    computed: {
        ...mapState(["shop_name", 'topTabList' ,'activeName','WS_OK','cashier']),
    },
    methods:{
        ...mapActions(['updateTabListAction','activeNameAction']),
        vuexHandle(data){
            this.activeNameAction(data.name)
            let arr = JSON.parse(JSON.stringify(this.topTabList))
            let i = arr.findIndex(item => item.name == data.name)
            if(i < 0){
                data.isDetele = true
                arr.push(data)
                this.updateTabListAction(arr)
            }
        },
        goYingye(){
            this.vuexHandle({
                title:'收银',
                path: this.cashier == 1 ? '/yingye' : '/quickCashier',
                name: this.cashier == 1 ? 'Yingye' : 'quickCashier'
            })
            let arr = JSON.parse(JSON.stringify(this.topTabList))
            let i = arr.findIndex(item => item.name == 'jiaoban')
            if(i > 0){
                arr.splice(i,1)
                this.updateTabListAction(arr)
            }
            
            this.$router.push(this.cashier == 1 ? '/yingye' : '/quickCashier');
        }
    }
}
</script>